import { Button, Grid, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// css
import '../../styles/InfoLayout.css';
// redux
import { addressAndMapActions } from '../../redux/addressAndMapSlice';
// dbquery
import { countClick, recordUserLogs, saveOrGetProjectDetails } from '../../dbquery/dbquery';
// helper
import { handlePayment, openInNewTab } from '../../helper';
import { downloadDrainsFiles } from '../../helper/DownloadDrainsFile';

const InfoLayout = (props: {
    lastClickAddress: any;
    setLastClickAddress: (lastClickAddress: any) => void;
    setOpenCouncilRulePage: (openCouncilRulePage: boolean) => void;
    showCouncilRulesNotification: boolean;
}) => {
    const { lastClickAddress, setLastClickAddress } = props;
    const dispatch = useDispatch();
    const accessTokenJWT = useSelector((state: any) => state.accountToken.accessTokenJWT);
    const idToken = useSelector((state: any) => state.accountToken.idToken);
    const refreshToken = useSelector((state: any) => state.accountToken.refreshToken);

    const handleConfirmLocation = async (e: any) => {
        if (
            !(
                e.target.innerText.toLowerCase().startsWith('osd calculation') ||
                e.target.innerText.toLowerCase().startsWith('bom') ||
                e.target.innerText.toLowerCase().startsWith('rwt tools') ||
                e.target.innerText.toLowerCase().startsWith('audit') ||
                e.target.innerText.toLowerCase().startsWith('stormwater') ||
                e.target.innerText.toLowerCase().startsWith('beta')
            )
        ) {
            if (
                lastClickAddress?.rainfallStation === '' ||
                lastClickAddress?.rainfallStation === undefined
            ) {
                alert(
                    `The location is currently not available for ${e.target.innerText.trim()}. Please try another location or contact us`,
                );
                return;
            }
        }

        dispatch(addressAndMapActions.setAddress(lastClickAddress!));

        const projectId = await saveOrGetProjectDetails(
            idToken.email,
            lastClickAddress!.address,
            lastClickAddress!.suburb,
            lastClickAddress!.state,
            lastClickAddress!.postcode,
            lastClickAddress!.council,
            lastClickAddress!.rainfallStation,
            lastClickAddress!.coordinate.lat,
            lastClickAddress!.coordinate.lng,
        )
            .then((result) => result[0].project_id)
            .catch((error) => console.log(error));

        recordUserLogs(projectId, 'UserPortal', `Clicked ${e.target.innerText}`);
        if (e.target.innerText.toLowerCase().trim() === 'john connor online') {
            openInNewTab(
                `https://www.jco-test.stormupdated.com.au/?token=${accessTokenJWT}&&project=${projectId}&&refresh=${refreshToken}`,
            );
        } else if (e.target.innerText.toLowerCase().startsWith('su')) {
            openInNewTab(
                `https://www.calculator.stormupdated.com.au/wsud?token=${accessTokenJWT}&&project=${projectId}&&refresh=${refreshToken}`,
            );
        } else if (e.target.innerText.toLowerCase().startsWith('osd')) {
            openInNewTab(
                `https://www.calculator.stormupdated.com.au/osds4vips?token=${accessTokenJWT}&&project=${projectId}&&refresh=${refreshToken}`,
            );
        } else if (e.target.innerText.toLowerCase().startsWith('aud')) {
            openInNewTab(
                `https://www.calculator.stormupdated.com.au/auditor-converter?token=${accessTokenJWT}&&project=${projectId}&&refresh=${refreshToken}`,
            );
        } else if (e.target.innerText.toLowerCase().startsWith('bom')) {
            openInNewTab(
                `https://bom-the-builder.stormupdated.com.au/?token=${accessTokenJWT}&&project=${projectId}&&refresh=${refreshToken}`,
            );
        } else if (e.target.innerText.toLowerCase().startsWith('rwt tools')) {
            openInNewTab(
                `https://www.calculator.stormupdated.com.au/rwt-demand-tool?token=${accessTokenJWT}&&project=${projectId}&&refresh=${refreshToken}`,
            );
        } else if (e.target.innerText.toLowerCase().startsWith('stormwater')) {
            openInNewTab(
                `https://main.d254fxwjpr0jf.amplifyapp.com/?token=${accessTokenJWT}&&project=${projectId}&&refresh=${refreshToken}`,
            );
        } else if (e.target.innerText.toLowerCase().startsWith('beta')) {
            openInNewTab(
                `https://master.d3rcnup82xp72o.amplifyapp.com/?token=${accessTokenJWT}&&project=${projectId}&&refresh=${refreshToken}`,
            );
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
        setLastClickAddress(undefined);
    };
    const downloadFiles = async () => {
        downloadDrainsFiles(
            lastClickAddress!.coordinate.lat,
            lastClickAddress!.coordinate.lng,
            lastClickAddress!.address,
        );
        countClick(idToken.email, 47);
    };
    const handleUnableToPay = () => {
        alert('Payment failed, please recharge 6.90 storm dollars to download the files.');
    };
    const handleDownloadFile = async (
        user_id: string,
        email: string,
        isSubscribedUser: boolean,
    ) => {
        handlePayment(user_id, email, isSubscribedUser, 6.9, downloadFiles, handleUnableToPay);
    };
    return (
        <div className="info-layout">
            <h2>Location Information:</h2>
            <div className="address">
                <p>
                    <b>Address: </b> {lastClickAddress!.address} <br />
                </p>
                <p>
                    <b>Council: </b> {lastClickAddress!.council} <br />
                </p>
                <p>
                    <b>Rainfall Station:</b> {lastClickAddress!.rainfallStation}
                </p>
            </div>
            <br />
            {props.showCouncilRulesNotification && (
                <div className="council-rules">
                    <p>
                        Please note: this council has special rules, please make sure you are
                        familiar with these before you start modelling.
                    </p>
                    <Button
                        variant="outlined"
                        size="small"
                        className="council-rules-button"
                        onClick={() => props.setOpenCouncilRulePage(true)}
                    >
                        Check Council Rules
                    </Button>
                </div>
            )}

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className="service-row">
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleConfirmLocation}
                            disabled={!lastClickAddress?.servicesAvailable?.includes('J')}
                        >
                            John Connor Online
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleConfirmLocation}
                            disabled={!lastClickAddress?.servicesAvailable?.includes('S')}
                        >
                            SU Calculator
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="service-row">
                        <Button variant="outlined" onClick={handleConfirmLocation}>
                            RWT Tools
                        </Button>
                        <Button variant="outlined" size="small" onClick={handleConfirmLocation}>
                            OSD Calculation
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="service-row">
                        <Tooltip
                            placement="top"
                            arrow
                            enterTouchDelay={500}
                            title="AR&R & IFD Data"
                        >
                            <Button variant="outlined" onClick={handleConfirmLocation}>
                                Stormwater Design Tools
                            </Button>
                        </Tooltip>
                        <Button variant="outlined" onClick={handleConfirmLocation}>
                            AUDIT/CONVERT MODELS
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="service-row">
                        <Button variant="outlined" onClick={handleConfirmLocation}>
                            Bom The Builder
                        </Button>
                        <Button variant="outlined" onClick={handleConfirmLocation}>
                            BETA BTB
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default InfoLayout;
